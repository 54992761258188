<template>
  <!-- Traitement phytosanitaire -->
  <div class="fiche-pesees-phyto-subview">
    <teleport to="#header-action">
      <Btn
        v-if="helperService.userHasPermission('essaievenement_oedit')"
        class="action-btn"
        icon="create"
        color="white"
        hollow
        text="Modifier"
        :to="{
          name: 'essaiExperimentalTaskFichePeseesPhytoEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid,
          }
        }"
      />
    </teleport>
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Fiche de pesées</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn
            color="primary"
            hollow
            text="Exporter la fiche de pesées"
            @click="download('xls', 'evenement', $route.params.tid)"
          />
        </div>
        <Table
          :headers="headers"
          :static-headers="staticHeaders"
          :items="formattedModalities"
          :hiddenItems="['id', 'ordre']"
          :tools="false"
        />
      </Container>
    </Section>
  </div>
  <Loader :active="loading"/>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Container from '@/components/layout/Container.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'FichePeseesPhytoSubview',
  components: {
    Btn,
    Table,
    Container,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      headers: [],
      staticHeaders: [
        'Cadence de <br>traitement',
        'Produit',
        'Formulation',
        'Dose <br>épandue',
        'Densité',
        'Nb rép.',
        'Surface <br>application',
        'Vol (pds) <br>/ surface',
        'Quant. <br>calculée',
        'Pesée <br>théorique <br>mini',
        'Pesée <br>réelle',
        'Pesée <br>théorique <br>maxi',
        'Réalisé <br>par',
        'Réalisé <br>le',
        'Observations',
      ],
      modalities: {},
      formattedModalities: [],
    }
  },

  emits: ['set-editable'],

  async mounted() {
    this.loading = true
    this.modalities = await this.getModalites()
    this.formattedModalities = this.formatModalities(this.modalities)
  },
  methods: {
    async getModalites() {
      const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/phytosanitaire/pesee/modalite`
      const response = await this.fetchService.get(endpoint, { limit: 0 })
      const datas = await response.data
      return datas
    },

    formatModalities(modalities) {
      let datas = []
      modalities.forEach((modality) => {
        const data = {
          id: modality.id,
          ordre: modality.modalite?.ordre,
          cadence_traitement: modality.fiche_phytosanitaire_pesee?.cadence_traitement,
          produit: modality.produit?.designation,
          formulation: modality.produit?.formulation?.valeur,
          dose_epandue: this.taskService.concatValueUnit(
            modality.produit_phytosanitaire?.dose ?? 'NC',
            modality.produit_phytosanitaire?.unite ? modality.produit_phytosanitaire.unite.valeur : '',
          ),
          densite: modality.densite,
          nb_repetitions: modality.nb_repetitions,
          surface_application: this.taskService.concatValueUnit(
            modality.surface_application ?? 'NC',
            modality.surface_application ? 'm²' : '',
          ),
          volume_surface: this.taskService.concatValueUnit(
            modality.surface ? this.helperService.round(modality.surface, 3) : 'NC',
            modality.unite ? modality.unite.valeur : '',
          ),
          quantite: this.taskService.concatValueUnit(
            modality.quantite ? this.helperService.round(modality.quantite, 3) : 'NC',
            modality.quantite ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
          ),
          pesee_min: this.taskService.concatValueUnit(
            modality.pesee_min ? this.helperService.round(modality.pesee_min, 3) : 'NC',
            modality.pesee_min ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
          ),
          pesee_reelle: this.taskService.concatValueUnit(
            modality.pesee_reelle ? this.helperService.round(modality.pesee_reelle, 3) : 'NC',
            modality.pesee_reelle ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
          ),
          pesee_max: this.taskService.concatValueUnit(
            modality.pesee_max ? this.helperService.round(modality.pesee_max, 3) : 'NC',
            modality.pesee_max ? modality.fiche_phytosanitaire_pesee?.unite?.valeur : '',
          ),
          trigramme: modality.trigramme,
          date: modality.date?.split(' ').shift(),
          observations: modality.observations,
        }
        datas.push(data)
      })
      datas = this.helperService.sortByOrder(datas)
      this.loading = false
      return datas
    },

    download(type, ressource, rid) {
      this.loading = true
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/fiche-pesee`
      const filename = `evenement_${rid}_fiche_pesee_phytosanitaire`
      this.fileService.downloadFile(endpoint, filename, type)
      this.loading = false
    },
  },
}
</script>
